//BODY.scss
//styles for the body and html element

@mixin font-regular {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-weight: 500;
}

* {
  box-sizing: border-box;
}

* :focus{
  outline: none;
}

html {
  font-size: $font-size-base;
    scroll-behavior: smooth;
}

body {
  //position: relative;
  font-size: inherit;
  min-height: 100vh;
  margin: 0;
  line-height: $line-height-base;
  @include font-regular;
  color: $color-font-main;
  background-color: #fff;
  cursor: default;
}

.no-scroll{
  overflow: auto;
  overflow-x: hidden;
}

button,
a,
img {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include font-regular;
}

figure {
    margin: 0;
}
