@import '../general/variables.scss';

.ab-feed {
    width: 100% !important;
    box-shadow: inherit !important;
    background-color: inherit !important;
    border-radius: 0 !important;
    overflow-y: inherit !important;
    overflow-x: inherit !important;

    .ab-feed-buttons-wrapper {
        display: none;
    }

    .ab-feed-body {
        padding: 0 !important;
        border: 0 !important;
    }

    .ab-card {
        border: 0 !important;
        box-shadow: inherit !important;
        border-radius: 0 !important;
        padding: 14px !important;
        margin-bottom: 0 !important;

        &.ab-unread-indicator:not(.read) {
            background-color: #F8F8F8 !important;
            border-color: transparent !important;
            border-radius: 5px !important;

            -webkit-transition: all 200ms linear;
            -moz-transition: all 200ms linear;
            -o-transition: all 200ms linear;
            transition: all 200ms linear;

            .ab-description {
                color: black !important;
                border: 0 !important;
                padding-bottom: 0 !important;
            }

        }

        &:last-child {
            .ab-description {
                border: 0 !important;
                padding-bottom: 0 !important;
            }
        }

        .ab-card-body {
            padding-left: 0 !important;
        }

        .ab-image-area {
            width: 50px !important;
            height: 50px !important;
        }

        &.with-image {
            .ab-title {
                padding-left: 65px !important;
                padding-bottom: 20px !important;
                min-height: 56px;
            }
            .ab-unread-indicator {
                top: 17px !important;
                left: 56px !important;
                border: 2px solid white !important;
            }
        }

        .ab-title, .ab-description {
            padding: 0 !important;
            font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
            font-weight: 500 !important;
        }

        .ab-description {
            font-size: 13px !important;
            color: #a7a7a7 !important;
            line-height: 1.5 !important;
            padding-bottom: 30px !important;
            border-bottom: 1px solid #dbdbdb !important;

            a {
                color: black !important;
                text-decoration: underline;
                &:hover {
                    color: black !important;
                    text-decoration: none;
                }
            }
        }

        .ab-title {
            padding-right: 15px !important;
            margin-bottom: 10px !important;
            color: black !important;
            font-weight: normal !important;
            font-size: 14px !important;
            line-height: 1.3 !important;
        }

        .ab-unread-indicator {

            bottom: inherit !important;
            top: 2px !important;
            right: 2px !important;
            width: 10px !important;
            height: 10px !important;
            background-color: #ff001f !important;
            border-radius: 50% !important;

            &.read {
                display: none !important;
            }
        }

        .ab-image-area {
            padding-left: 0 !important;
            padding-top: 5px !important;
        }

        .ab-close-button {
            display: none !important;
        }
    }
}
