@import 'variables.scss';
@import 'mixins.scss';

//TYPOGRAPHY.LESS
//basic typo settings and helper classes

//FONT DEFINITIONS
//************

.font-regular {
    @include font-regular;
}

.font-bold {
    @include font-bold;
}

//CLASSES
//**********

//helper classes

    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }

    .text-right{
        text-align: right;
    }
    .text-small {
        font-size: $font-size-small;
    }

    .page-headline-sm{
        font-size: $font-size-normal;
        color: $color-font-grey;
        margin-bottom: 10px;
        text-transform: uppercase;
        &.inverse {
            color: $color-font-blue-light;
        }
        &.blue {
            color: $color-orange;
        }
    }

    .page-headline-lg-smaller{
        @extend .page-headline-lg-smaller;
    }

    .text-largest {
        font-size: $font-size-largest;
    }




.page-headline-lg {
    font-size: $font-size-largest;
    color: $color-font-main;
    margin-top: 0;
    line-height: $line-height-base;
    margin-bottom: 10px;
    white-space: pre-line;
    &.inverse {
        color: $color-font-white;
    }
    &.blue {
        color: #004660;
    }
    @media (max-width: $screen-lg) {
        font-size: $font-size-larger;
    }
    @media (max-width: $screen-sm) {
        font-size: 1.6rem;
    }
}

.page-headline-lg-smaller {
    @extend .page-headline-lg;
    font-size: $font-size-larger;
    @media (max-width: $screen-lg) {
        font-size: $font-size-large;
    }
    @media (max-width: $screen-sm) {
        font-size: 1.5rem;
    }
}

body {
    .color-grey {
        color: $color-font-grey;
    }

    .color-blue-light {
        color: $color-font-blue-light;
    }

    .color-white {
        color: #ffffff;
    }

    .color-primary {
        color: $color-primary;
    }

    .color-green {
        color: $color-confirm;
    }

    .color-red {
        color: $color-error;
    }

    .sidebar-title {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: $font-size-small;
        color: $color-font-grey;
    }

    .sidebar-subtitle {
        font-size: $font-size-small;
        margin-bottom: 10px;
    }

    .sidebar-title-flex-between {
        display: flex;
        justify-content: space-between;
    }

    .link-primary {
        color: $color-primary;

        &:hover {
            color: $color-primary-dark;
        }
    }
}

strong.text-selectable {
    -webkit-user-select: all; /* Chrome all / Safari all */
    -moz-user-select: all; /* Firefox all */
    -ms-user-select: all; /* IE 10+ */
    user-select: all; /* Likely future */
}


@mixin hasActionMenu{
    @extend .hasActionMenu;
}



.hasActionMenu {
    overflow: hidden;
}
.hasActionMenu:hover {
    .menuClosed {
        opacity: 1;
        display: block;
        top: 5px;
    }
}
