button {
    background-color: transparent;
    border-style: none;
    color: inherit;
    font-size: 1em;
    margin: 0;
}
$btn-radius: 4px;

//CLASSES
.btn {
    border: 1px solid $color-border;
    border-radius: $btn-radius;
    text-align: center;
    padding: 8px 10px;
    background-color: #fff;
    text-decoration: none;
    box-shadow: none;
    display: inline-block;
    font-size: $font-size-normal;
    line-height: $line-height-base;
    color: #000;
    cursor: pointer;
    vertical-align: middle;
    white-space: nowrap;
    z-index: 1;
    text-transform: none;
    @include font-regular;

    &:hover {
        background-color: darken(#fff, 5%);
        text-decoration: none;
    }
    &.active {
        background-color: darken(#fff, 5%);
    }
    &:focus {
        outline: none;
    }

    @media (max-width: $screen-sm) {
        font-size: $font-size-small;
    }
}

.btn-with-icon-right {
    img {
        margin-right: 10px;
    }
}

.btn-full {
    width: 100%;
}

.btn:disabled,
.btn-disabled {
    border-color: $color-border-disabled;
    color: $color-font-grey;
    &:hover {
        background-color: transparent;
        color: $color-font-grey;
    }
}

.btn-xsmall{
    font-size: $font-size-small;
    padding: 2px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-small {
    font-size: $font-size-small;
    padding: 8px 12px;
}

.btn-large {
    padding: 10px 15px;
}

.btn-xlarge {
    padding: 13px 15px;
    line-height: $line-height-large;
}

.btn-confirm {
    background-color: $color-confirm;
    border: 1px solid $color-confirm;
    color: #fff;
    &:hover {
        background-color: darken($color-confirm, 5%);
        border-color: darken($color-confirm, 5%);
    }
    &:disabled {
        border-color: #dbdbdb;
        background-color: #dbdbdb;
        color: #ffffff;
        &:hover {
            background-color: #dbdbdb;
            color: #ffffff;
            border-color: #dbdbdb;
        }
    }
}

.btn-danger {
    background-color: $color-error;
    border: 1px solid $color-error;
    color: #fff;
    &:hover {
        background-color: darken($color-error, 5%);
        border-color: darken($color-error, 5%);
    }
    &:disabled {
        border-color: #dbdbdb;
        background-color: #dbdbdb;
        color: #fff;
        &:hover {
            background-color: #dbdbdb;
            color: #ffffff;
            border-color: #dbdbdb;
        }
    }
}

.btn-orange {
    background-color: $color-orange;
    border: 1px solid $color-orange;
    color: #fff;
    &:hover {
        background-color: darken($color-orange, 5%);
        border-color: darken($color-orange, 5%);
    }
}

.btn-cancel {
    background-color: $color-bg-grey;
    border: 1px solid $color-bg-grey;
    &:hover {
        background-color: darken($color-bg-grey, 10%);
    }
}

.btn-black {
    background-image: linear-gradient(225deg, #002847 0%, #001129 100%);
    background-color: #001129;
    border: 1px solid #001129;
    color: #fff;
    &:hover {
        color: #fff;
        background-color: darken(#001129, 10%);
    }
}

.btn-grey {
    background-color: $color-bg-grey;
    border: 1px solid $color-bg-grey;
    color: $color-font-main;
    &:hover,
    &.active {
        color:   $color-font-main;
        background-color: #ffffff;
    }
}

.btn-dashed {
    border-style: dashed;
    color: #b3b3b3;
    text-transform: none !important;
}

.btn-text {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;

    &:hover {
        background-color: transparent;
        text-decoration: none;
    }
}

.btn-white {
    border: none;
    background-color: #fff;
    color: $color-font-main;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-out;

    &:hover {
        background-color: #fff;
        box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.4);
        border: none;
        color: $color-font-main;
    }

    .small-shadow {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
        &:hover {
            box-shadow: 0 13px 20px 0 rgba(0, 0, 0, 0.3);
        }
    }
}

.btn-rounded {
    border-radius: 18px;
}

.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 6px;
    }
}
