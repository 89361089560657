//padding classes
body {
    .m-t-xs {
        margin-top: $space-xs;
    }

    .m-t-sm {
        margin-top: $space-sm;
    }

    .m-t-def {
        margin-top: $space-def;
    }

    .m-t-md {
        margin-top: $space-md;
    }

    .m-t-lg {
        margin-top: $space-lg;
    }

    .m-b-xs {
        margin-bottom: $space-xs;
    }

    .m-b-sm {
        margin-bottom: $space-sm;
    }

    .m-b-md {
        margin-bottom: $space-md;
    }

    .m-b-lg {
        margin-bottom: $space-lg;
    }

    .m-l-xs {
        margin-left: $space-xs;
    }

    .m-r-xs {
        margin-right: $space-xs;
    }

    .m-l-sm {
        margin-left: $space-xs;
    }

    .m-r-sm {
        margin-right: $space-xs;
    }

    .p-b-md {
        padding-bottom: $space-md;
    }

    .p-t-md {
        padding-top: $space-md;
    }
}
