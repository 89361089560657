//FONT MIXINS
//************
//Fonts and font weights used in document stored as Mixins

@mixin font-regular {
	font-family: 'Montserrat', Helvetica, Arial, sans-serif;
	font-weight: 500;
}

@mixin font-bold {
	font-family: 'Montserrat', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
}