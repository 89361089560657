.hidden{
    display: none!important;
}

.rounded{
    border-radius: 50%;
}

.hidden-sm{
    @media (max-width: $screen-sm) {
        display: none;
    }
}

.hidden-md{
    @media (max-width: $screen-md) {
        display: none;
    }
}

.blurhash + img.lazyloaded, .blurhash + video.lazyloaded {
    opacity: 1 !important;
}

.ovf-hidden {
    overflow: hidden;
}
