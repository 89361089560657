////////////////
// HTML elements
////////////////
h1 {
    font-size: $font-size-largest;
    margin-top: 0;
    margin-bottom: 10px;
    @include font-regular;
    @media (max-width: $screen-lg) {
        font-size: $font-size-larger;
    }
    @media (max-width: $screen-sm) {
        font-size: 1.6rem;
    }
}

h2 {
    font-size: $font-size-large;
    margin-top: 0;
    margin-bottom: 15px;
    @include font-regular;
}

h3 {
    font-size: $font-size-normal-large;
    margin-bottom: 15px;
    color: $color-font-main;
    margin-top: 0;
    @include font-regular;
}

h4 {
    font-size: $font-size-normal;
    margin-bottom: 15px;
    color: $color-font-main;
    margin-top: 0;
    @include font-regular;
}

a {
    color: $color-font-main;
    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: underline;
    }
}

p {
    margin-top: 0;
    line-height: $line-height-large;
}

kbd {
    padding: 0.1em 0.6em;
    border: 1px solid #ccc;
    font-size: 11px;
    background-color: #f7f7f7;
    color: #333;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
    border-radius: 3px;
    display: inline-block;
    margin: 0 0.1em;
    text-shadow: 0 1px 0 #fff;
    line-height: 1.4;
    white-space: nowrap;
}

input{
    padding: 5px 10px;
    font-size: $font-size-normal;
    border: 1px solid $color-border;
    border-radius: $btn-radius;
    background-color: #fff;
    @include font-regular;
    &:focus {
        outline: none;
        box-shadow: 0 0 5px 0 rgba(200, 200, 200, 0.4);
    }
}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        cursor: default;
    }
}
