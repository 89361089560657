//////////
//COLORS
//////////
$color-primary: #00BAFF;
$color-primary-dark: #0087b9;

//fonts
$color-font-main: #000000;
$color-font-grey:  #a7a7a7;
$color-font-blue-light: #A2E6FF;
$color-font-white: #FFFFFF;

//background
$color-bg-grey: #f2f2f2;
$color-bg-grey-light: #f5f5f5;

//borders
$color-border: #C8C8C8;
$color-border-light: #DCDCDC;
$color-border-disabled: #ECEEF6;

$color-error: #ff001f;
$color-confirm: #00BD28;
$color-yellow: #F6C423;
$color-orange:#FF6600;

//collections filter
$color-bg-violet: #F4F4FB;
$color-font-violet: #3023AE;

/////////////
// TYPOGRAPHY
/////////////
//base font size
$font-size-base: 0.938rem; //15;

//Font size scale
$font-size-smaller: 0.733rem; //11
$font-size-small: 0.867rem; //13
$font-size-normal: 1rem; //15
$font-size-normal-large: 1.133rem; //17
$font-size-large: 1.733rem; //26;
$font-size-larger: 2rem; //30;
$font-size-largest: 2.4rem; //36
$font-size-jumbo: 3.333rem; //50

//Line height scale
$line-height-small: 1;
$line-height-base: 1.3;
$line-height-large: 1.5;
$line-height-larger: 1.7;

////////////////
// SCREEN SIZES
///////////////
$screen-xsm: 359px;
$screen-sm: 767px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1440px;
$screen-xxl: 1650px;

$screen-h-lg: 850px;

//THUMBS BREAkPOINTS
$screen-2-thumbs: 860px;
$screen-3-thumbs: 1280px;
$screen-4-thumbs: 1640px;

///////////////////
// SPACING
//////////////////
$space-xs: 9px;
$space-sm: 18px;
$space-def: 40px;
$space-md: 60px;
$space-lg: 120px;

//////////////////
// CONTAINERS
//////////////////
$container-small: 1154px;
$container: 1280px;
$container-large: 1785px;


/////////////////
// BUTTONS
/////////////////
$btn-radius: 4px;
