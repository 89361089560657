@import '../../styles/general/variables';

.notFoundWrapper{
    margin: auto;
    min-height: 40vh;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .errorWrapper {
        white-space: pre-wrap;
        margin-bottom: 100px;
        font-size: $font-size-small;
        color: $color-font-grey;
    }

    .headlineWrapper{
        margin: 100px auto;
        max-width: 720px;
        width: 100%;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

