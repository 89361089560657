@import '../../../styles/general/variables';

.spinner > span {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    display: inline-flex;
    vertical-align: middle;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.spinnerGrey > span {
    background-color: $color-font-grey;
}

.spinnerDarkGrey > span {
    background-color: $color-font-main;
}

.small > span {
    width: 8px;
    height: 8px;
}
