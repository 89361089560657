@import '../../../styles/general/variables';

$btn-radius: 4px;

.btn {
    border-radius: $btn-radius;
    text-align: center;

    text-decoration: none;
    box-shadow: none;
    display: inline-block;
    line-height: $line-height-base;

    cursor: pointer;
    vertical-align: middle;
    white-space: nowrap;
    z-index: 1;
    text-transform: none;

    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;
    }

    @media (max-width: $screen-sm) {
        font-size: $font-size-small;
    }
}

.block {
    width: 100%;
}

//sizes
.xsmall {
    font-size: $font-size-small;
    padding: 2px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small {
    font-size: $font-size-small;
    padding: 8px 12px;
}

.medium {
    font-size: $font-size-normal;
    padding: 8px 10px;
}

.large {
    font-size: $font-size-normal;
    padding: 10px 15px;
}

.xlarge {
    font-size: $font-size-normal;
    padding: 12px 15px;
    line-height: $line-height-large;
}

.xxlarge {
    font-size: $font-size-normal;
    padding: 12px 25px;
    line-height: $line-height-large;
}

//color variations
.default {
    border: 1px solid $color-border;
    background-color: #fff;
    color: $color-font-main;
    &:hover, &:focus {
        background-color: darken(#fff, 5%);
        text-decoration: none;
        border: 1px solid $color-border-light;
    }
    &.active {
        background-color: darken(#fff, 5%);
    }
    &:disabled, &.disabled {
        border-color: $color-border-disabled;
        color: $color-font-grey;
        &:hover {
            background-color: transparent;
            color: $color-font-grey;
        }
    }
}

.disabled {
    border-color: $color-border-disabled;
    color: $color-font-grey;

    &:hover {
        background-color: transparent;
        color: $color-font-grey;
    }
}

.confirm {
    background-color: $color-confirm;
    border: 1px solid $color-confirm;
    color: #fff;

    &:hover, &:focus {
        background-color: darken($color-confirm, 5%);
        border-color: darken($color-confirm, 5%);
    }

    &:disabled, &.disabled {
        border-color: #dbdbdb;
        background-color: #dbdbdb;
        color: #ffffff;

        &:hover {
            background-color: #dbdbdb;
            color: #ffffff;
            border-color: #dbdbdb;
        }
    }
}

.danger {
    background-color: $color-error;
    border: 1px solid $color-error;
    color: #fff;

    &:hover, &:focus {
        background-color: darken($color-error, 5%);
        border-color: darken($color-error, 5%);
    }

    &:disabled, &.disabled {
        border-color: #dbdbdb;
        background-color: #dbdbdb;
        color: #fff;

        &:hover {
            background-color: #dbdbdb;
            color: #ffffff;
            border-color: #dbdbdb;
        }
    }
}

.orange {
    background-color: $color-orange;
    border: 1px solid $color-orange;
    color: #fff;

    &:hover, &:focus {
        background-color: darken($color-orange, 5%);
        border-color: darken($color-orange, 5%);
    }
}

.grey {
    background-color: $color-bg-grey;
    border: 1px solid $color-bg-grey;
    color: $color-font-main;

    &:hover,
    &.active,
    &:focus {
        color: $color-font-main;
        background-color: #ffffff;
    }
}

.black {
    background-color: $color-font-main;
    border: 1px solid $color-font-main;
    color: #ffffff;

    &:hover,
    &.active,
    &:focus {
        color: $color-font-main #ffffff;
        background-color: $color-font-main;
    }
}

.dashed {
    border-style: dashed;
    color: #b3b3b3;
    border-color: $color-border;
    text-transform: none !important;

    &:hover, &:focus {
        background-color: darken(#fff, 5%);
        text-decoration: none;
    }

    &.active {
        background-color: darken(#fff, 5%);
    }

    &:disabled, &.disabled{
        border-color: $color-border-disabled;
        color: $color-font-grey;

        &:hover {
            background-color: transparent;
            color: $color-font-grey;
        }
    }
}

.text {
    color: $color-font-main;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;

    &:hover, &:focus {
        background-color: transparent;
        text-decoration: none;
    }

    &.active {
        color: $color-font-main;
    }

    &:disabled, &.disabled {
        color: $color-font-grey;
    }
}

.white {
    border: none;
    background-color: #fff;
    color: $color-font-main;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-out;

    &:hover, &:focus {
        background-color: #fff;
        box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.4);
        border: none;
        color: $color-font-main;
    }
}

.rounded {
    border-radius: 18px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
}
