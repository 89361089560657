//FONT IMPORTS
//*************

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    src: url('/fonts/montserrat-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/montserrat-bold.woff2') format('woff2');
}

//VARIABLES AND COLORS
@import './general/variables';

//MIXINS
@import './general/mixins';

//GENERAL STYLES
@import './general/body.scss';
@import './general/html.scss';
@import './general/spacing.scss';
@import './general/typography.scss';
@import './general/helpers.scss';
@import './general/buttons.scss';
@import './patterns/brazeCards';

